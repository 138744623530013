<script setup>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";
import { useLocale } from 'vuetify'

const props = defineProps(["component", "props"]);
const theme = useTheme();
const lang = useI18n();
const { current } = useLocale()

onMounted(() => {
  window.addEventListener("theme-changed", (event) => {
    theme.global.name.value = event.detail.theme;
  });

  window.addEventListener("lang-changed", (event) => {
    if (event.detail.lang) {
      lang.locale.value = event.detail.lang;
      current.value = event.detail.lang;
    }
  });
});
</script>

<template>
  <component :is="props.component" v-bind="props.props"></component>
</template>

<style>
body {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
